import { useEffect, useImperativeHandle, useRef } from "react";

// Temporary local copy of hook until shadcn merges a PR. Remove it when merged.
// https://github.com/shadcn-ui/ui/pull/1681

export const useAutoResizeTextarea = (
  ref: React.ForwardedRef<HTMLTextAreaElement>,
  autoResize: boolean
) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useImperativeHandle(ref, () => textAreaRef.current!);
  useEffect(() => {
    const ref = textAreaRef?.current;
    const updateTextareaHeight = () => {
      if (ref && autoResize) {
        const scrollHeight = `${ref.scrollHeight}px`;
        const computedStyleMap = ref.computedStyleMap();
        const borderTopWidth =
          computedStyleMap.get("border-top-width")?.toString() ?? "0";
        const borderBottomWidth =
          computedStyleMap.get("border-bottom-width")?.toString() ?? "0";
        ref.style.height = `calc(${scrollHeight} + ${borderTopWidth} + ${borderBottomWidth})`;
      }
    };
    updateTextareaHeight();
    ref?.addEventListener("input", updateTextareaHeight);
    return () => ref?.removeEventListener("input", updateTextareaHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { textAreaRef };
};
